import $ from 'jquery';
import component from './_component';


export default {
  init() {
    $(function() {
      $('form#visit').on('submit', function(e) {
        e.preventDefault();
        if ($('#code').val() === 'mulabo!2020') {
          if (typeof window.sessionStorage !== 'undefined') {
            window.sessionStorage.setItem('is-login', '1');
            location.href = './mulabo-wallpaper-download.html';
          }
        } else {
          $('#error').show().focus();
          $('#code').attr('aria-invalid', 'true');
        }
      });
    });
  }
}