import $ from 'jquery';
import component from './_component';
import getUniqueStrId from '../_helper/_getUniqueStrId';

function init() {
  var modalClose = function () {
    $(document).off('keydown.modal-key-check');
    
    $('.c-modal.is-active').each(function () {
      var $this = $(this);

      $this.animate({
        opacity: 0
      }, 300, function () {
        $this.removeClass('is-active').css({
          opacity: ''
        });
        if (!$('.c-modal.is-active')[0]) {
          $('html').removeClass('is-modal-active');
        }

        var focusBack = $this.attr('data-focus-back');
        var $focusBack = $('#' + focusBack);
        if(focusBack && $focusBack[0]) {
          $focusBack.focus();
          $this.attr('data-focus-back', '');
        }
      });
    });
  };

  $(document).on('click', '.js-modal_btn', function (e) {
    e.preventDefault();

    var $btn = $(this);
    var id = $btn.attr('id');
    var target = $btn.attr('data-target');
    var $target = $(target);

    if ($target[0]) {
      $('html').addClass('is-modal-active');
      if(id) $target.attr('data-focus-back', id);
      $target.addClass('is-active').css({
        opacity: 0
      });
      $target.animate({
        opacity: 1
      }, 300, function () {
        $target.css({
          opacity: ''
        });
      });

      if ($target.find('.js-modal_close')[0]) {
        $target.find('.js-modal_close').focus();
      } else {
        $target.find(':_focusable').eq(0).focus();
      }
      
      var thisFocusIndex = 0;

      $(document).on('keydown.modal-key-check', function(e) {
        if (e.key === 'Tab') {
          e.preventDefault();
          var focusLen = $target.find(':_focusable').length;
          thisFocusIndex = $target.find(':_focusable').index($(':focus'));
          var next = thisFocusIndex;
  
          if(e.shiftKey) {
            next--;
            next = (next < 0) ? focusLen - 1 : next;
          } else {
            next++;
            next = (next >= focusLen) ? 0 : next;
          }
  
          $target.find(':_focusable').eq(next).focus();
        }
      });
    }
  });

  $(document).on('click', '.js-modal_close', function (e) {
    e.preventDefault();
    modalClose();
  });
}

function ready() {
  $('.js-modal_btn').each(function() {
    if ($(this).hasClass('is-initialized')) return;

    var $btn = $(this);
    var btnDataTarget = $btn.attr('data-target');
    var btnId = $btn.attr('id');
    var $contents = $(btnDataTarget);
    if ($contents[0] && $contents.hasClass('js-modal_contents')) {
      if (!btnId) {
        btnId = getUniqueStrId('a11y-modal');
        $btn.attr('id', btnId);
      }
      $contents.attr({
        'aria-modal': true,
        'role': 'dialog'
      });

      $(this).addClass('is-initialized');
    }
  });
}

export default {
  init() {
    init();
    $(function() {
      ready();
    });
  }
}