export default {
  keys: {
    end: 35,
    home: 36,
    left: 37,
    up: 38,
    right: 39,
    down: 40,
    delete: 46
  },

  direction: {
    37: -1,
    38: -1,
    39: 1,
    40: 1
  },

  easeIn: 'easeInQuad',
  easeOut: 'easeOutQuad',

  transitionEndEvents: [
    'webkitTransitionEnd.transitionend',
    'mozTransitionEnd.transitionend',
    'oTransitionEnd.transitionend',
    'transitionend.transitionend'
  ].join(' '),

  loopFocusableIntercept: 0
};
