import $ from 'jquery';
import component from './_component';

function init() {

}
function ready() {
  var $pagetop = $('.js-pagetop');
  $(window).on('scroll', function (e) {
    if ($(this).scrollTop() > 200) {
      $pagetop.fadeIn();
    } else {
      $pagetop.fadeOut();
    }
  }).trigger('scroll');
}

export default {
  init() {
    init();
    $(function() {
      ready();
    });
  }
}
