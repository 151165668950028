import $ from 'jquery';
import component from './_component';


export default {
  init() {
    $(function() {
      const $linkToEn = $('.js-link-to-en');
      const currentPath = location.pathname;
      const englishSitePath = "/en-global" + currentPath;
      $linkToEn.attr('href', englishSitePath);

      const $linkToJa = $('.js-link-to-ja');
      const japaneseSitePath = currentPath.replace(/\/en-global/g, '')
      $linkToJa.attr('href', japaneseSitePath);
    });
  }
}
