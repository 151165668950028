import $ from 'jquery';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import component from './_component';


var headerToggleSet = function() {
  $('.js-header_toggle-target').each(function() {
    try {
      var $this = $(this);
      var responsive = $this.attr('data-responsive');
      var aria = eval('(' + responsive + ')');
      var id = $this.attr('id');
      var $controls = $('.js-header_toggle-open').filter('[aria-controls="' + id + '"]');

      if (typeof aria !== 'undefined') {
        if (component.matchMedia.sp().matches) {
          $this.attr({
            'aria-hidden': String(!aria.sp)
          });
          $controls.attr({
            'aria-expanded': String(aria.sp)
          });
        }
        if (component.matchMedia.tb().matches) {
          $this.attr({
            'aria-hidden': String(!aria.tb)
          });
          $controls.attr({
            'aria-expanded': String(aria.tb)
          });
        }
        if (component.matchMedia.pc().matches) {
          $this.attr({
            'aria-hidden': String(!aria.pc)
          });
          $controls.attr({
            'aria-expanded': String(aria.pc)
          });
        }
      }
      
    } catch(e) {
      // console.log(e)
    }
  });
};

function init() {
  component.matchMedia.sptb().addListener(function () {
    menuReset(true);
  });

  var menuReset = function(isDirect) {
    headerToggleAllReset(isDirect);
    glovalMenuClose($('.js-gnav'), {
      isDirect: isDirect,
      isSlide: !isDirect,
      isFade:  !isDirect
    });
  };

  var onGnav = false;

  var onLoopFocusable = function($this, $target) {
    const loopFocusableCnt = ++component.loopFocusableIntercept;
    component.loopFocusableIntercept = loopFocusableCnt;
    $target.data({'data-loopfocusable-cnt': loopFocusableCnt});

    $(document).on('keydown.header-key-check', function(e) {
      if (component.loopFocusableIntercept !== $target.data('data-loopfocusable-cnt') * 1) return;

      if (e.key === 'Tab') {
        e.preventDefault();
        var $focusable = $target.not('.js-dummy-focus').find(':_focusable');

        if ($this.attr('data-focus-loop') === 'true' || component.matchMedia.sptb().matches) {
          // 自身をフォーカスループに含めるか
          $focusable = $focusable.add($this);
        }

        var focusLen = $focusable.length;
        var nextIndex = $focusable.index($(':focus'));

        if(e.shiftKey) {
          nextIndex--;
          nextIndex = (nextIndex < 0) ? focusLen - 1 : nextIndex;
        } else {
          nextIndex++;
          nextIndex = (nextIndex >= focusLen) ? 0 : nextIndex;
        }

        $focusable.eq(nextIndex).focus();
      }

      if (e.key === 'Esc') {
        menuReset(false);
      }
    });
  }

  
  var headerToggleClose = function($this, $target) {
    $this.removeClass('is-active');
    $this.addClass('is-close-enter');
    $('html').removeClass('is-gnav-open');
    $target.find('.js-dummy-focus').attr('tabindex', '-1');

    $('.p-header__overlay').fadeOut(300, function() {
      $(this).css({'display': ''});
    });
    $target.fadeOut(300, function () {
      $this.removeClass('is-close-enter');
      $target.css({'display': ''});
      $target.removeClass('is-active');
    });

    $this.attr({
      'aria-expanded': 'false'
    });
    $target.attr({
      'aria-hidden': 'true'
    });

    component.loopFocusableIntercept = --component.loopFocusableIntercept;

    $target.removeClass('is-disable-body-scroll');
    enableBodyScroll($target[0]);
  };

  var headerToggleOpen = function($this, $target) {
    var $overlay = $('.p-header__overlay');
    var overlayHidden = $overlay.is(':hidden');
    menuReset(true);

    $this.addClass('is-active');
    $('html').addClass('is-gnav-open');

    if (overlayHidden) {
      $overlay.show().css({opacity: 0});
      $overlay.animate({opacity: 1}, 300, function() {
        $target.css({opacity: ''});
      });
    } else {
      $overlay.show();
    }

    $target.show().css({opacity: 0});
    $target.animate({opacity: 1}, 300, function() {
      $target.css({opacity: ''});
    });
    
    $target.addClass('is-active');

    $this.attr({
      'aria-expanded': 'true'
    });
    $target.attr({
      'aria-hidden': 'false'
    });

    if ($target.find('.js-dummy-focus')[0]) {
      $target.find('.js-dummy-focus').attr('tabindex', '0');
      $target.find('.js-dummy-focus').focus();
    } else {
      $target.find(':_focusable').eq(0).focus();
    }

    $(window).scrollTop(0);
    onLoopFocusable($this, $target);
    disableBodyScroll($target[0]);
    $target.addClass('is-disable-body-scroll');
  };

  var headerToggleAllReset = function(isDirect) {
    $(document).off('keydown.header-key-check');
    $('html').removeClass('is-gnav-open');
    $('.js-header_toggle-open').removeClass('is-active');
    $('.js-header_toggle-target').find('.js-dummy-focus').attr('tabindex', '-1');
    
    if (isDirect) {
      $('.p-header__overlay').css({display: ''});
      $('.js-header_toggle-target').css({display: ''});
    } else {
      $('.p-header__overlay').fadeOut(300, function() {
        $(this).css({display: ''});
      });

      $('.js-header_toggle-target').each(function() {
        var $this = $(this);
        var responsive = $this.attr('data-responsive');
        var aria = eval('(' + responsive + ')');
  
        if (typeof aria !== 'undefined') {
          if (component.matchMedia.sp().matches) {
            if (!aria.sp) {
              $this.fadeOut(300, function () {
                $this.css({display: ''});
              });
            } else {
              $this.css({display: ''});
            }
          }
          if (component.matchMedia.tb().matches) {
            if (!aria.tb) {
              $this.fadeOut(300, function () {
                $this.css({display: ''});
              });
            } else {
              $this.css({display: ''});
            }
          }
          if (component.matchMedia.pc().matches) {
            if (!aria.pc) {
              $this.fadeOut(300, function () {
                $this.css({display: ''});
              });
            } else {
              $this.css({display: ''});
            }
          }
        }
      });
    }

    headerToggleSet();
    clearAllBodyScrollLocks();
    $('.is-disable-body-scroll').addClass('is-disable-body-scroll');
  };

  
  
  var focusOutTimer = 0;
  $(document).on('mouseenter focusin', '.js-gnav', function (e) {
    onGnav = true;
    clearTimeout(focusOutTimer);
  });
  $(document).on('mouseleave focusout', '.js-gnav', function (e) {
    onGnav = false;
  });

  var onGnavFocusCheck = function() {
    $(document).on('keydown.gnav-focus-check', function(e) {
      if (e.key === 'Tab') {
        focusOutTimer = setTimeout(function() {
          if (!onGnav) {
            if (!component.matchMedia.sptb().matches) {
              glovalMenuClose($('.js-gnav'), {
                isFade: true
              });
            }
          }
        }, 30);
      }
    });
  };
  var offGnavFocusCheck = function() {
    $(document).off('keydown.gnav-focus-check');
  };

  var glovalMenuAttrChange = function($el, isOpen) {
    var $gnavLink = $el.find('.js-gnav_link');
    var $gnavContents = $el.find('.js-gnav_contents');
    if (isOpen) {
      $gnavLink.attr({'aria-expanded': 'true'});
      $gnavContents.attr({'aria-hidden': 'false'});
      $gnavLink.addClass('is-active');
      $el.addClass('is-active');
    } else {
      $gnavLink.attr({'aria-expanded': 'false'});
      $gnavContents.attr({'aria-hidden': 'true'});
      $gnavLink.removeClass('is-active');
      $el.removeClass('is-active');
    }
  };

  var glovalMenuAllClose = function(arg) {
    $('.js-gnav_item').each(function() {
      glovalMenuClose($(this), arg);
    });
  };
  
  var glovalMenuClose = function($box, arg) {
    offGnavFocusCheck();

    var $gnavItem = $box.hasClass('js-gnav_item') ? $box : $box.find('.js-gnav_item');
    $gnavItem.find('.js-gnav_contents').find('.js-dummy-focus').attr('tabindex', '-1');

    glovalMenuAttrChange($gnavItem, false);

    if (arg.isFadeContent) {
      $gnavItem.stop(true, true).find('.js-gnav_contents').fadeOut(150, function() {});
    } else if (arg.isFade) {
      $gnavItem.stop(true, true).find('.js-gnav_contents').fadeOut(300, function() {
      });
      if (!component.matchMedia.sptb().matches) {
        $('.p-header__overlay').fadeOut(300, function() {});
      }
    } else if (arg.isDirect) {
      $gnavItem.find('.js-gnav_contents').hide();
      if(!onGnav) $('.p-header__overlay').hide();
    } else {
      $gnavItem.find('.js-gnav_contents').slideUp(300, function () {
      });
      if (!component.matchMedia.sptb().matches) {
        $('.p-header__overlay').fadeOut(300, function() {});
      }
    }
  };

  var glovalMenuOpenTimer = 0;
  var glovalMenuOpen = function($box, arg) {
    /*
    arg = {
      isDirect: boolean,
      isFade: boolean,
      isSlide: boolean
    }
    */

    onGnavFocusCheck();

    var $gnavItem = $box.hasClass('js-gnav_item') ? $box : $box.find('.js-gnav_item');
    $gnavItem.find('.js-gnav_contents').find('.js-dummy-focus').attr('tabindex', '0');
    
    if (!component.matchMedia.sptb().matches) {
      // headerToggleAllReset();
    }

    clearTimeout(glovalMenuOpenTimer);

    if (arg.isFadeContent) {
      $gnavItem.stop(true, true).find('.js-gnav_contents').fadeIn();
    } else if (arg.isFade) {
      // glovalMenuAllClose(arg);
      $gnavItem.stop(true, true).find('.js-gnav_contents').fadeIn();
      $('.p-header__overlay').stop().fadeIn(0, function() {});

      glovalMenuOpenTimer = setTimeout(function() {
        // $gnavItem.stop(true, true).find('.js-gnav_contents').fadeIn();
        // $('.p-header__overlay').stop().fadeIn(0, function() {});
      }, 0);
    } else if (arg.isDirect) {
      glovalMenuAllClose(arg);
      $gnavItem.find('.js-gnav_contents').show();
      $('.p-header__overlay').stop().fadeIn(0, function() {});
    } else if (arg.isSlide) {
      $gnavItem.find('.js-gnav_contents').slideDown(300, function () {});
      $('.p-header__overlay').stop().fadeIn(300, function() {});
    }

    glovalMenuAttrChange($gnavItem, true);

  }
  
  $(document).on('click', '.js-gnav_link', function (e) {
    var $this = $(this);
    var $box = $(this).closest('.js-gnav_item');
    var $parentGnav = $this.closest('.js-gnav');
  
    e.preventDefault();

    if ($box.hasClass('is-active')) {
      if (component.matchMedia.sptb().matches) {
        // sptb
        glovalMenuClose($box, {
          isSlide: true
        });
      } else {
        glovalMenuClose($box, {
          isFade: true
        });
      }
    } else {
      if (component.matchMedia.sptb().matches) {
        // sptb
        glovalMenuOpen($box, {
          isSlide: true
        });
      } else {
        // pc
        var isOpen = $parentGnav.find('.js-gnav_link').filter('[aria-expanded="true"]')[0];

        if (isOpen) {
          glovalMenuClose($('.js-gnav'), {
            isFadeContent: true
          });
          setTimeout(function() {
            glovalMenuOpen($box, {
              isFadeContent: true
            });
          }, 150);
        } else {
          menuReset(true);
          glovalMenuOpen($box, {
            isFade: true
          });
        }
      }
    }
  });

  $(document).on('click', '.js-gnav_close', function (e) {
    glovalMenuClose($('.js-gnav'), {
      isFade: true
    });
  });
  $(document).on('click', '.js-header_close', function (e) {
    menuReset(false);
  });
  
  
  $(document).on('click', '.js-header_toggle-open', function (e) {
    e.preventDefault();
    var $this = $(this);
    var $target = $('#' + $this.attr('aria-controls'));
    $(document).off('keydown.header-key-check');

    if ($this.hasClass('is-active')) {
      headerToggleClose($this, $target);
    } else {
      headerToggleOpen($this, $target);
    }
  });

  $(document).on('click', '.js-header_toggle-close', function (e) {
    var id = $(this).closest('.js-header_toggle-target').attr('id');
    $('.js-header_toggle-open[aria-controls="' + id + '"]').eq(0).trigger('click');
  });
}

function ready() {
  $('.js-gnav').find('.js-gnav_item').each(function (itemIndex) {
    $(this)
      .find('.js-gnav_link')
      .attr({
        role: 'button',
        'aria-expanded': 'false',
        'aria-controls': 'gnav_contents_' + itemIndex
      });
    $(this)
      .find('.js-gnav_contents')
      .attr({
        id: 'gnav_contents_' + itemIndex,
        'aria-hidden': 'true'
      });
  });

  headerToggleSet();
}


export default {
  init() {
    init();
    $(function() {
      ready();
    });
  }
}

