import $ from 'jquery';
import component from './_component';

function init() {
  $(document).on('click', '.js-toggle_btn', function (e) {
    e.preventDefault();

    var $btn = $(this);
    var $target = $('#' + $btn.attr('aria-controls'));

    if ($target[0]) {
      if ($target.attr('aria-hidden') === 'false') {
        $btn.attr('aria-expanded', false);
        $target.attr('aria-hidden', true);
        $btn.addClass('is-close');
        $btn.removeClass('is-open');
      } else {
        $btn.attr('aria-expanded', true);
        $target.attr('aria-hidden', false);
        $btn.removeClass('is-close');
        $btn.addClass('is-open');
      }
    }
  });

  $(document).on('click', '.js-toggle_close', function (e) {
    e.preventDefault();
  });
}

function toggleReset() {
  $('.js-toggle_btn').each(function(i) {
    var $btn = $(this);
    var $wrap = $btn.closest('.js-toggle');
    var responsive = $wrap.attr('data-responsive');
    var aria = eval('(' + responsive + ')');
    var mqList = ['sp', 'tb', 'pc'];
    var targetId = $btn.attr('data-target');
    var $target = (function() {
      if (targetId) {
        return $('#' + targetId);
      } else {
        targetId = $wrap.find('.js-toggle_contents').attr('id');
        return $wrap.find('.js-toggle_contents');
      }
    })();

    var set = function(mq) {
      if (component.matchMedia[mq]().matches) {
        var _open = function() {
          $target.attr({ 'aria-hidden': false });
          $btn.attr({ 'aria-expanded': true });
        }
        var _close = function() {
          $target.attr({ 'aria-hidden': true });
          $btn.attr({ 'aria-expanded': false });
        }
        if ($btn.hasClass('is-open')) {
          _open();
        } else if ($btn.hasClass('is-close') && aria[mq] !== null) {
          _close();
        } else if (aria[mq] === null || aria[mq] === true) {
          _open();
        } else if (aria[mq] === false) {
          _close();
        }

        if (aria[mq] === null) {
          $wrap.addClass('is-toggle-null');
        } else {
          $wrap.removeClass('is-toggle-null');
        }
      }
    }

    if (typeof aria !== 'undefined') {
      for (let index = 0; index < mqList.length; index++) {
        set(mqList[index]);
      }
    }
  });
}

function toggleInit() {
  $('.js-toggle_btn').each(function(i) {
    var $btn = $(this);
    var $wrap = $btn.closest('.js-toggle');

    if ($wrap.hasClass('is-initialized')) return;

    var responsive = $wrap.attr('data-responsive');
    var aria = eval('(' + responsive + ')');
    var mqList = ['sp', 'tb', 'pc'];
    var targetId = $btn.attr('data-target');
    var isTargetNullId = false;
    var $target = (function() {
      if (targetId) {
        return $('#' + targetId);
      } else {
        isTargetNullId = true;
        if ($wrap.find('.js-toggle_contents').attr('id')) {
          targetId = $wrap.find('.js-toggle_contents').attr('id');
        } else {
          targetId = 'a11y-toggle-contents' + i;
          $wrap.find('.js-toggle_contents').attr('id', targetId);
        }
        return $wrap.find('.js-toggle_contents');
      }
    })();

    var set = function(mq) {
      if (component.matchMedia[mq]().matches) {
        if (aria[mq] === null) {
          $target.attr({'aria-hidden': false});
          $btn.attr({'aria-expanded': true});
          $wrap.addClass('is-toggle-null');
        } else {
          $target.attr({ 'aria-hidden': !aria[mq] });
          $btn.attr({ 'aria-expanded': aria[mq] });
          $wrap.removeClass('is-toggle-null');
        }
      }
    }

    if ($target[0]) {
      var btnId = $btn.attr('id') ? $btn.attr('id') : 'a11y-toggle-btn' + i;
      
      $btn.attr('aria-controls', targetId);
      $btn.attr('id', btnId);

      if (typeof aria !== 'undefined') {
        for (let index = 0; index < mqList.length; index++) {
          set(mqList[index]);
        }
      } else {
        if ($btn.hasClass('is-open') || $btn.attr('aria-expanded') === 'true' || $target.attr('aria-hidden') === 'false') {
          $btn.attr('aria-expanded', true);
          $target.attr('aria-hidden', false);
        } else {
          $btn.attr('aria-expanded', false);
          $target.attr('aria-hidden', true);
        }
      }

      $wrap.addClass('is-initialized');
    }
  });
}

function ready() {
  component.matchMedia.sp().addListener(function (mql) {
    if (mql.matches) toggleReset();
  });
  component.matchMedia.tb().addListener(function (mql) {
    if (mql.matches) toggleReset();
  });
  component.matchMedia.pc().addListener(function (mql) {
    if (mql.matches) toggleReset();
  });

  toggleInit();
}

export default {
  init() {
    init();
    $(function() {
      ready();
    });
  },
  toggleInit: toggleInit
}