import $ from 'jquery';
import component from './_component';

function init() {

}
function ready() {
  $('.js-tab').each(function(tabIndex) {
    if ($(this).hasClass('is-initialized')) return;
  
    var $tabwrap = $(this);
    var $tablist = $(this).find('[role="tablist"]');
    var $tablistToggle = $(this).find('.js-tablist-toggle');
    var $tabs = $(this).find('[role="tab"]');
    var $panels = $(this).find('[role="tabpanel"]');
    var focused;
    var delay = 150;
  
    var tablistId = $tablistToggle.attr('id') ? $tablistToggle.attr('id') : 'tablist-toggle' + tabIndex;
    $tablistToggle.attr('aria-controls', 'tablist' + tabIndex);
    $tablistToggle.attr('id', tablistId);
    // $tablist.attr('aria-labelledby', tablistId);
    $tablist.attr('id', 'tablist' + tabIndex);
  
    if (component.matchMedia.sp().matches) {
      $tablistToggle.removeClass('is-open').attr('aria-expanded', false);
      $tablist.removeClass('is-open').attr('aria-hidden', true);
    } else {
      $tablistToggle.addClass('is-open').attr('aria-expanded', true);
      $tablist.addClass('is-open').attr('aria-hidden', false);
    }
  
    $tabs.each(function(index) {
      this.index = index;
      var $tab = $(this);
      var target = $(this).attr('data-target');
      var $target = $('#' + target);
      var tabId = $tab.attr('id') ? $tab.attr('id') : target + '-btn';
      $tab.attr('aria-controls', target);
      $tab.attr('id', tabId);
      // $target.attr('aria-labelledby', tabId);
      $target.attr('aria-hidden', !$tab.hasClass('is-selected'));
      $target.attr('tabindex', 0);
    });
    $tabs.filter('[aria-selected="false"]').attr('tabindex', '-1');
  
    var determineOrientation = function(e) {
      var key = e.keyCode;
      var proceed = false;
  
      if (
        key === component.keys.left ||
        key === component.keys.right ||
        key === component.keys.up ||
        key === component.keys.down
      ) {
        e.preventDefault();
        proceed = true;
      }
  
      if (proceed) {
        switchTabOnArrowPress(e);
      }
    };
  
    var switchTabOnArrowPress = function(e) {
      var pressed = e.keyCode;
  
      $tabs.on('focus', focusEventHandler);
  
      if (component.direction[pressed]) {
        var target = e.target;
        if (target.index !== undefined) {
          if ($tabs.eq(target.index + component.direction[pressed])[0]) {
            $tabs.eq(target.index + component.direction[pressed]).focus();
          } else if (pressed === component.keys.left || pressed === component.keys.up) {
            focusLastTab();
          } else if (pressed === component.keys.right || pressed == component.keys.down) {
            focusFirstTab();
          }
        }
      }
    };
  
    var deactivateTabs = function() {
      $tabs.removeClass('is-selected');
      $tabs.attr('tabindex', '-1');
      $tabs.attr('aria-selected', 'false');
      $tabs.off('focus', focusEventHandler);
      $panels.attr('aria-hidden', 'true');
    };
  
    var activateTab = function($tab, setFocus) {
      setFocus = setFocus || true;
      deactivateTabs();
  
      $tab.addClass('is-selected');
      $tab.removeAttr('tabindex');
      $tab.attr('aria-selected', 'true');
      $tabwrap
        .find('#' + $tab.attr('aria-controls'))
        .attr('aria-hidden', 'false');
  
      if (setFocus) {
        $tab.focus();
      }
      
      if (component.matchMedia.sp().matches) {
        $tablistToggle.trigger('click');
      }
    };
    
    var focusedTab = function($tab) {
      $tabs.attr('tabindex', '-1');
      $tabs.off('focus', focusEventHandler);
      $tab.removeAttr('tabindex');
      $tab.focus();
    };
  
    var focusFirstTab = function() {
      $tabs.eq(0).focus();
    };
    var focusLastTab = function() {
      $tabs.eq($tabs.length - 1).focus();
    };
  
    var focusEventHandler = function(e) {
      var target = e.target;
      setTimeout(checkTabFocus, delay, target);
    };
  
    var checkTabFocus = function(target) {
      focused = document.activeElement;
      if (target === focused) {
        if (component.matchMedia.sp().matches) {
          focusedTab($(target));
        } else {
          activateTab($(target), false);
        }
      }
    };
  
    $tablistToggle.on('click', function (e) {
      e.preventDefault();
      
      var $btn = $(this);
      var $target = $('#' + $btn.attr('aria-controls'));
  
      if ($target[0]) {
        if ($target.attr('aria-hidden') === 'false') {
          $btn.attr('aria-expanded', false);
          $target.attr('aria-hidden', true);
          $btn.removeClass('is-open');
          $target.removeClass('is-open');
        } else {
          $btn.attr('aria-expanded', true);
          $target.attr('aria-hidden', false);
          $btn.addClass('is-open');
          $target.addClass('is-open');
        }
      }
    });
  
    $tabs.on('click', function(e) {
      if ($(this).attr('data-target')) {
        e.preventDefault();
        activateTab($(this), false);
      } else {
        if (component.matchMedia.sp().matches) {
          $tablistToggle.trigger('click');
        }
      }
    });
  
    $tabs.on('keydown', function(e) {
      var key = e.keyCode;
      switch (key) {
        case component.keys.end:
          e.preventDefault();
          activateTab($tabs.eq($tabs.length - 1));
          break;
        case component.keys.home:
          e.preventDefault();
          activateTab($tabs.eq(0));
          break;
        case component.keys.up:
        case component.keys.down:
          determineOrientation(e);
          break;
      }
    });
  
    $tabs.on('keyup', function(e) {
      var key = e.keyCode;
      switch (key) {
        case component.keys.left:
        case component.keys.right:
          determineOrientation(e);
          break;
      }
    });
    
    $(this).addClass('is-initialized');
  });
}

export default {
  init() {
    init();
    $(function() {
      ready();
    });
  }
}