window.component = window.component || {};
let component = window.component;

function typeOf(obj) {
  const toString = Object.prototype.toString;
  return toString.call(obj).slice(8, -1).toLowerCase();
}

component.set = (addObject) => {
  if (typeOf(addObject) === 'object') {
    component = Object.assign(component, addObject);
  }
};

component.add = (key, value) => {
  component[key] = value;
};

export default component;
