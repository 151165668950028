import component from './_component';

/*
@custom-media --sp only screen and (min-width: 0px) and (max-width: 767px);
@custom-media --sptb only screen and (min-width: 0px) and (max-width: 1024px);
@custom-media --tb only screen and (min-width: 768px) and (max-width: 1024px);
@custom-media --tbpc only screen and (min-width: 768px);
@custom-media --pc only screen and (min-width: 1025px);
@custom-media --large only screen and (min-width: 768px) and (max-width: 1300px);
@custom-media --xlarge only screen and (min-width: 1301px);

@custom-media --spmax only screen and (min-width: 0px) and (max-width: 768px);
@custom-media --pcmin only screen and (min-width: 769px) and (max-width: 1024px);
@custom-media --pcmax only screen and (min-width: 769px);

*/

const matchMedia = {
  sp: function() { return window.matchMedia('(max-width: 767px)')},
  sptb: function() { return window.matchMedia('(max-width: 1024px)')},
  tb: function() { return window.matchMedia('(min-width: 768px) and (max-width: 1024px)')},
  tbpc: function() { return window.matchMedia('(min-width: 768px)')},
  pc: function() { return window.matchMedia('(min-width: 1025px)')}
};

export default matchMedia;
