import $ from 'jquery';
import component from './_component';

function init() {
  $(document).on('click', '.js-anchor[href^="#"]', function (e) {
    var self = this;
    var target = $(this).attr('href');
    var $target = target === '#' ? $('html') : $(target);

    if ($target[0]) {
      e.preventDefault();

      $('body, html').animate({
        scrollTop: $target.offset().top
      }, 500, component.easeOut, function() {
        if (target === '#') {
          $('body').attr('tabindex', '-1');
          setTimeout(function() {
            $('body').focus();
            $('body').removeAttr('tabindex');
          });
        } else {
          window.location.hash = target
        }
      });

      /*
      var notBeforeMove = $(window).scrollTop() > $('.p-header').height();
      var scrollFunc = function () {
        var position = $target.offset().top;
        var $header = $('.p-header');
        var headerH = $header.css('position') === 'fixed' ? $header.height() + 10 : 0;
        $('body, html').animate({
          scrollTop: position - headerH
        }, 500, component.easeOut);
      };

      if (notBeforeMove) {
        scrollFunc();
      } else {
        $('body, html').animate({
          scrollTop: $('.p-header').height() + 10
        }, 500, scrollFunc);
      }
      */
    }
  });
}
function ready() {

}

export default {
  init() {
    init();
    $(function() {
      ready();
    });
  }
}