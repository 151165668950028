import $ from 'jquery';
import { throttle, debounce } from 'throttle-debounce';
import component from './_component';

function checkScrollbar() {
  var scrollbarWidth = document.body.clientWidth;
  var notScrollbarWidth = window.innerWidth;
  if (scrollbarWidth >= notScrollbarWidth) {
    $('html').removeClass('is-scrollbar');
  } else {
    $('html').addClass('is-scrollbar');
  }
}

function init() {
}
function ready() {
  const observer = new MutationObserver(checkScrollbar);
  observer.observe(document.body, {childList: true, subtree: true});
  window.addEventListener('scroll', throttle(200, checkScrollbar), false);
  window.addEventListener('resize', throttle(200, checkScrollbar), false);
}

export default {
  init() {
    init();
    $(function() {
      ready();
    });
  }
}
