import "core-js/stable"
import "regenerator-runtime/runtime"
import $ from 'jquery';

import component from './_import/_component';
import base from './_import/_base';
import matchMedia from './_import/_match-media';
import pagetop from './_import/_pagetop';
import anchorLink from './_import/_anchor-link';
import tab from './_import/_tab';
import toggle from './_import/_toggle';
import modal from './_import/_modal';
import globalNav from './_import/_global-nav';
import isScrollbar from './_import/_isScrollbar';
// import popupToggle from './_import/_popup-toggle';
// import menu2nd from './_import/_menu-2nd';
// import menu2ndFixed from './_import/_menu-2nd-fixed';
// import filterTab from './_import/_filter-tab';
// import sliderRecommendLinks from './_import/_slider-recommend-links.js';
// import scrollhint from './_import/_scrollhint.js';
import visitLogin from './_import/_visit-login.js';
import changeLang from './_import/_change-lang.js';
import footerTooltip from './_import/_footer-tooltip.js';

/* index */
// import indexInit from './_import/_index/_index';


component.set(base);
component.add('matchMedia', matchMedia);

pagetop.init();
globalNav.init();
anchorLink.init();
tab.init();
toggle.init();
modal.init();
isScrollbar.init();
visitLogin.init();
changeLang.init();
footerTooltip.init();
// indexInit.init();
// popupToggle.init();
// filterTab.init();
// menu2nd.init();
// menu2ndFixed.init();
// sliderRecommendLinks.init();
// scrollhint.init();

component.add('tab', tab);
component.add('modal', modal);
component.add('toggle', toggle);

const init = () => {
  $('html').removeClass('no-js');
};

init();

$(function() {

});
