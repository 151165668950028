import $ from 'jquery';

export default {
  init() {
    $(function() {
      const $button = $('.js-footer-tooltip-button');
      const $tooltip = $('.js-footer-tooltip');

      const isTooltipOpen = () => {
        return $button.attr('aria-expanded') === 'true'
      }

      const openTooltip = () => {
        $button.attr('aria-expanded', 'true');
        $tooltip.show();
      }

      const closeTooltip = () => {
        $button.attr('aria-expanded', 'false');
        $tooltip.hide();
      }

      const onDocumentClick = (e) => {
        const isTooltipClicked = $(e.target).closest('.js-footer-tooltip').length;
        const isTooltipButtonClicked = $(e.target).closest('.js-footer-tooltip-button').length
        if(isTooltipClicked || isTooltipButtonClicked) {
          return;
        } 
        
        closeTooltip();
        document.removeEventListener('click', onDocumentClick);
      }

      $button.on('click', () => {
        if(!isTooltipOpen()) {
          openTooltip();
          document.addEventListener('click', onDocumentClick);
          return false;
        }
      })
    
    });
  }
}
